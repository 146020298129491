import Vue from 'vue'
import Vuex from 'vuex'
import config from '@/utils/config'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// 显示医保信息
		showYbmessage: true,
		// 显示就诊码信息
		showJzmmessage: true,
		// 显示最新信息
		showDotBool: false,
		lineYbUrl: config.lineYbUrl,
		onlineAdress: config.onlineAdress,
		local: config.local,
		appid: config.appid
	},
	getters: {},
	mutations: {
		SET_DOT: (state, showDotBool) => {
			state.showDotBool = showDotBool
		}
	},
	actions: {},
	modules: {}
})
